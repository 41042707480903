import React from "react"
import { GoogleLogin } from "react-google-login"
import { inject, observer, useLocalStore } from "mobx-react"

import RootLayout from "../../layouts/RootLayout"

const TimeSheets = ({ store, history }) => {
  const params = useLocalStore(() => ({
    email: "",
    password: "",
    set(e) {
      params[e.target.name] = e.target.value
    },
  }))

  return (
    <RootLayout>
      <GoogleLogin
        clientId={process.env.CLIENT_ID}
        onSuccess={(response) => {
          store.setToken(response.tokenId)
          history.replace("/timesheets")
        }}
      />
    </RootLayout>
  )
}

export default inject("store")(observer(TimeSheets))
