import { useState, useEffect } from "react"

export default function useScrollHeight(ref, deps) {
  const [height, setHeight] = useState(0)

  useEffect(() => {
    setHeight(ref.current.scrollHeight)
  }, [ref.current, ...deps])

  return height
}
