import React from "react"
import { Link } from "react-router-dom"
import classNames from "classnames"

const KINDS = {
  primary: "bg-blue-500",
  danger: "bg-red-500",
}

export default ({ asLink = false, kind = "primary", className, ...props }) => {
  const Component = asLink ? Link : "button"
  return (
    <Component
      className={classNames(
        className,
        "text-white rounded px-4 py-2",
        KINDS[kind],
      )}
      {...props}
    />
  )
}
