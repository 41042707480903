import { isSameDay, parseISO, formatISODate } from "./date"

export function findTimeEntry(timeEntries, day) {
  return timeEntries.find((n) => n.date === formatISODate(day))
}

export function findHoliday(holidays, day) {
  const entry = holidays.find((n) => isSameDay(parseISO(n.date), day))
  if (entry) {
    return entry.summary
  }
  return null
}

export function getHours(timeEntries, day, defaultValue = 0) {
  const entry = findTimeEntry(timeEntries, day)
  if (entry) {
    return entry.hours
  }
  return defaultValue
}

export function toCSV(timeEntries, holidays = []) {
  return [
    ["date", "hours", "holiday"].map(JSON.stringify),
    ...timeEntries.map(({ date, hours }) =>
      [date, hours, findHoliday(holidays, parseISO(date)) || undefined].map(
        JSON.stringify,
      ),
    ),
  ].join("\r\n")
}

export function exportCSV(basename, timeEntries, holidays = []) {
  const csv = toCSV(timeEntries, holidays)
  const link = document.createElement("a")
  link.setAttribute("href", encodeURI(`data:text/csv;charset=utf-8,${csv}`))
  link.setAttribute("download", `${basename}.csv`)
  document.body.appendChild(link)
  link.click()
  link.remove()
}
