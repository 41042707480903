import React from "react"
import { observer, useLocalStore } from "mobx-react"
import gql from "graphql-tag"
import { useMutation } from "draqula"

import { findHoliday } from "../../support/timesheet"
import {
  format,
  monthName,
  formatISODate,
  dateAt,
  parseISO,
  isSameMonth,
} from "../../support/date"
import Calendar from "../../components/Calendar"
import Button from "../../components/Button"

const UPSERT_TIME_ENTRIES = gql`
  mutation q($input: UpsertTimeEntryBatchInput!) {
    upsertTimeEntryBatch(input: $input) {
      clientMutationId
    }
  }
`

const TimeEntryForm = ({
  entries,
  holidays,
  month,
  year,
  workingHours,
  ...props
}) => {
  const date = dateAt(year, month)
  const { mutate } = useMutation(UPSERT_TIME_ENTRIES)

  const store = useLocalStore(() => ({
    entries,
    setEntry(day, hours) {
      store.entries.set(format(day, "yyyy-MM-dd"), hours)
    },
    get monthEntries() {
      return Array.from(store.entries.entries()).filter(([key]) =>
        isSameMonth(date, parseISO(key)),
      )
    },
    get totalHours() {
      return store.monthEntries.reduce((acc, [, value]) => acc + value, 0)
    },
  }))

  const renderDay = (day) => {
    return (
      <div className="text-sm">
        <input
          className="bg-transparent"
          type="number"
          min="0"
          max="24"
          value={store.entries.get(formatISODate(day))}
          onChange={(e) => {
            store.setEntry(day, Number(e.target.value))
          }}
        />{" "}
        hour(s)
      </div>
    )
  }

  const onSubmit = async (e) => {
    e.preventDefault()

    const timeEntries = store.monthEntries.map(([key, value]) => {
      return {
        date: key,
        hours: value,
      }
    })

    await mutate({
      input: {
        timeEntries,
      },
    })

    props.onSubmit()
  }

  return (
    <>
      <h2 className="mb-8 text-2xl">
        {monthName(date)} <span className="font-medium">{year}</span>
      </h2>
      <Calendar
        className="mb-8"
        year={year}
        month={month}
        renderDay={renderDay}
        getHoliday={(day) => findHoliday(holidays, day)}
      />
      <div className="flex">
        <Button onClick={onSubmit}>Submit</Button>
        <div className="flex flex-col items-end ml-auto">
          <div>
            <span className="text-gray-500 italic">
              {store.totalHours} hours
            </span>
          </div>
        </div>
      </div>
    </>
  )
}

export default observer(TimeEntryForm)
