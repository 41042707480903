import React from "react"
import { inject, observer } from "mobx-react"
import { Redirect, Switch } from "react-router"
import { Route } from "react-router-dom"

import ProtectedRoute from "./ProtectedRoute"
import TimeEntry from "./TimeEntry"
import Login from "./Login"
import Users from "./Users"
import User from "./User"
import TimeSheets from "./TimeSheets"
import Profile from "./Profile"

const Router = ({ store: { isLoggedIn } }) => {
  return (
    <Switch>
      <ProtectedRoute path="/time-entry/:year/:month" component={TimeEntry} />
      <ProtectedRoute path="/users/:id" component={User} />
      <ProtectedRoute path="/users" component={Users} />
      <ProtectedRoute path="/profile" component={Profile} />
      <ProtectedRoute path="/timesheets" component={TimeSheets} />
      <Route path="/login" component={Login} />
      {isLoggedIn ? <Redirect to="/timesheets" /> : <Redirect to="/login" />}
    </Switch>
  )
}

export default inject("store")(observer(Router))
