import { types } from "mobx-state-tree"

export default types
  .model({
    token: types.maybeNull(types.string),
  })
  .views((self) => ({
    get isLoggedIn() {
      if (self.token === null) {
        return false
      }
      return true
    },
  }))
  .actions((self) => ({
    setToken(token) {
      self.token = token
    },
  }))
