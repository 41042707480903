import React from "react"
import { observer } from "mobx-react"
import gql from "graphql-tag"
import { useQuery } from "draqula"

import useCurrentUser from "../../hooks/useCurrentUser"
import {
  dateAt,
  daysBetween,
  format,
  isWeekend,
  startOfMonth,
  endOfMonth,
} from "../../support/date"
import { findHoliday } from "../../support/timesheet"
import LoadingPlaceholder from "../../components/LoadingPlaceholder"

import TimeEntryForm from "./TimeEntryForm"

const ALL_TIME_ENTRIES = gql`
  query q($start: Date!, $end: Date!, $holidaysCalendarUrl: String) {
    ics(url: $holidaysCalendarUrl) {
      date
      summary
      uid
    }

    currentUser {
      timeEntries(
        filter: {
          date: { greaterThanOrEqualTo: $start, lessThanOrEqualTo: $end }
        }
      ) {
        nodes {
          date
          hours
          locked
        }
      }
    }
  }
`

const TimeEntry = ({ children, match, history, ...props }) => {
  const { year, month } = match.params
  const date = dateAt(year, month)

  const currentUser = useCurrentUser()

  const { data, isLoading } = useQuery(
    ALL_TIME_ENTRIES,
    {
      start: startOfMonth(date),
      end: endOfMonth(date),
      holidaysCalendarUrl: currentUser.holidaysCalendarUrl,
    },
    {
      cache: false,
    },
  )

  const onSubmit = () => {
    history.replace("/")
  }

  if (isLoading) {
    return <LoadingPlaceholder />
  }

  const entries = new Map()

  // prefill working days with 8 hours
  daysBetween(startOfMonth(date), endOfMonth(date)).forEach((day) => {
    entries.set(
      format(day, "yyyy-MM-dd"),
      isWeekend(day) || findHoliday(data.ics, day)
        ? 0
        : currentUser.workingHours,
    )
  })

  // override days with already filled hours
  data.currentUser.timeEntries.nodes.forEach((entry) => {
    entries.set(entry.date, entry.hours)
  })

  return (
    <div className="flex flex-auto items-center justify-center mx-auto container">
      <div className="p-8 border rounded shadow bg-white">
        <TimeEntryForm
          entries={entries}
          holidays={data.ics}
          year={year}
          month={month}
          workingHours={currentUser.workingHours}
          onSubmit={onSubmit}
        />
      </div>
    </div>
  )
}

export default observer(TimeEntry)
