import React from "react"
import { Link } from "react-router-dom"
import gql from "graphql-tag"
import { useQuery } from "draqula"

import LoadingPlaceholder from "../../components/LoadingPlaceholder"
import Card from "../../components/Card"

const User = ({ user }) => (
  <Link
    to={`/users/${user.id}`}
    className="flex items-center ml-auto p-2 py-3 border-b last:border-b-0"
  >
    <img className="h-8 w-8 mr-3 rounded-full" src={user.picture} />
    <div className="flex flex-col">
      <div>{user.name}</div>
      <div className="text-gray-500 text-xs">{user.email}</div>
    </div>
  </Link>
)

const USERS_QUERY = gql`
  {
    users {
      nodes {
        id
        name
        picture
        workingHours
        email
      }
    }
  }
`

const UsersRoute = () => {
  const { data, isLoading } = useQuery(USERS_QUERY)

  if (isLoading) {
    return <LoadingPlaceholder />
  }

  return (
    <>
      <h1 className="mb-8 text-2xl">Users</h1>
      <Card className="px-4 py-2">
        {data.users.nodes.map((user) => (
          <User key={user.id} user={user} />
        ))}
      </Card>
    </>
  )
}

export default UsersRoute
