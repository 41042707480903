import React from "react"

const EmptyPlaceholder = ({ message }) => (
  <div className="flex flex-auto flex-col items-center justify-center p-12 text-gray-400">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="4em"
      viewBox="0 0 512 512"
      className="fill-current"
    >
      <path d="M485.88,512H26.12V0h293L485.88,161.64ZM44.12,494H467.88V169.26L311.82,18H44.12Z" />
      <polygon points="477.08 174.17 306.66 174.17 306.66 8.96 324.66 8.96 324.66 156.17 477.08 156.17 477.08 174.17" />
    </svg>
    <div className="mt-4 italic">{message}</div>
  </div>
)

EmptyPlaceholder.defaultProps = { message: "Nothing to show" }

export default EmptyPlaceholder
