import React, { useEffect } from "react"

const RootLayout = ({ children, ...props }) => {
  return (
    <div className="flex flex-auto items-center justify-center mx-auto container">
      {children}
    </div>
  )
}

export default RootLayout
