import React, { useEffect } from "react"
import { inject } from "mobx-react"
import { Route, Switch } from "react-router-dom"
import { Redirect } from "react-router"

import ProtectedLayout from "../layouts/ProtectedLayout"

const ProtectedRoute = ({
  store: { isLoggedIn },
  component: Component,
  ...props
}) => {
  if (isLoggedIn) {
    return (
      <Route
        {...props}
        render={(matchProps) => (
          <ProtectedLayout>
            <Switch>
              <Component {...matchProps} />
            </Switch>
          </ProtectedLayout>
        )}
      />
    )
  }
  return <Redirect to="/login" />
}

export default inject("store")(ProtectedRoute)
