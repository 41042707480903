import React, { useEffect } from "react"
import gql from "graphql-tag"
import { useQuery } from "draqula"
import { NavLink } from "react-router-dom"
import classNames from "classnames"

import useCurrentUser from "../hooks/useCurrentUser"
import UserProfile from "../components/UserProfile"
import LoadingPlaceholder from "../components/LoadingPlaceholder"
import ProtectedContext from "../contexts/ProtectedContext"

const NavItem = ({ to, children, className, ...props }) => {
  return (
    <li className={classNames(className, "mx-2")}>
      <NavLink
        className="text-gray-600 hover:text-black font-medium"
        activeClassName="text-blue-600"
        to={to}
      >
        {children}
      </NavLink>
    </li>
  )
}

const Nav = () => {
  const currentUser = useCurrentUser()

  return (
    <ul className="flex flex-auto -mx-2">
      <NavItem to="/timesheets">Time Sheets</NavItem>
      <NavItem to="/profile">Profile</NavItem>
      {currentUser.role === "ROLE_ADMIN" && (
        <NavItem to="/users" className="ml-auto">
          Users
        </NavItem>
      )}
    </ul>
  )
}

const ProtectedLayout = ({ children, ...props }) => {
  const { isLoading, data, refetch } = useQuery(gql`
    query CurrentUserQuery {
      currentUser {
        id
        email
        name
        picture
        workingHours
        holidaysCalendarUrl
        role
      }
    }
  `)

  if (isLoading) {
    return <LoadingPlaceholder />
  }

  const { currentUser } = data

  return (
    <ProtectedContext.Provider value={currentUser}>
      <div className="flex flex-col mx-auto container">
        <div className="flex items-center mb-8">
          <Nav />
          <UserProfile className="ml-8" user={currentUser} />
        </div>
        {children}
      </div>
    </ProtectedContext.Provider>
  )
}

export default ProtectedLayout
