import {
  eachDayOfInterval,
  endOfISOWeek,
  endOfMonth,
  isSameMonth,
  isSameDay,
  isWeekend,
  startOfISOWeek,
  startOfMonth,
  eachWeekOfInterval,
  format,
  parseISO,
  getYear,
  getMonth as _getMonth,
} from "date-fns"
import { range } from "lodash-es"

export {
  format,
  isWeekend,
  startOfMonth,
  isSameDay,
  endOfMonth,
  startOfISOWeek,
  isSameMonth,
  endOfISOWeek,
  getYear,
  parseISO,
}

// javascript months starts at 0 index...
export function dateAt(year, month) {
  return new Date(year, month - 1)
}

export function getMonth(date) {
  return _getMonth(date) + 1
}

export function formatISODate(date) {
  return format(date, "yyyy-MM-dd")
}

export function monthName(date, style = "long") {
  return date.toLocaleString("default", { month: style })
}

export function dayName(date, style = "long") {
  return date.toLocaleString("default", { weekday: style })
}

export function weekDays(style = "short") {
  return range(5, 12).map((day) => dayName(new Date(1970, 0, day), style))
}

export function weeksBetween(start, end) {
  return eachWeekOfInterval({ start, end }, { weekStartsOn: 1 })
}

export function daysBetween(start, end) {
  return eachDayOfInterval({ start, end })
}
