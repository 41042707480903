import React from "react"
import { hot } from "react-hot-loader/root"
import { observer } from "mobx-react"

import Router from "../routes"

const App = () => {
  return <Router />
}

export default hot((observer(App)))
