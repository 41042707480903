import React from "react"
import classNames from "classnames"
import { MdFileDownload, MdLock } from "react-icons/md"

import { dateAt, monthName, parseISO, isSameMonth } from "../support/date"
import { getHours, findHoliday, exportCSV } from "../support/timesheet"

import EmptyPlaceholder from "./EmptyPlaceholder"
import Expandable from "./Expandable"
import LoadingPlaceholder from "./LoadingPlaceholder"
import Calendar from "./Calendar"
import Button from "./Button"

const TimeSheetListItem = ({
  timeSheet,
  actions,
  onRequestData,
  onRemove,
  getBasename,
  onLock,
}) => {
  const { year, month } = timeSheet
  const date = dateAt(year, month)
  const [data, setData] = React.useState({
    ics: [],
    timeEntries: [],
  })
  const [isLoading, setLoading] = React.useState(true)

  const onExpand = async () => {
    setLoading(true)
    setData(await onRequestData(date))
    setLoading(false)
  }

  const onExport = async () => {
    exportCSV(
      await getBasename(year, month),
      data.timeEntries.filter((entry) =>
        isSameMonth(date, parseISO(entry.date)),
      ),
      data.holidays,
    )
  }

  const renderDay = (day) => {
    const hours = getHours(data.timeEntries, day)
    return (
      <div
        className={classNames("text-sm", {
          "text-gray-400": !hours,
        })}
      >
        {hours} hour(s)
      </div>
    )
  }

  return (
    <Expandable
      title={
        <div className="flex">
          {timeSheet.locked && (
            <span className="inline-flex items-center mr-4 px-2 rounded bg-red-500 text-shadow text-white text-xs">
              Locked
            </span>
          )}
          <div className="mr-2 font-medium">{year}</div>
          <div className="mr-2">{monthName(date)}</div>
          <div className="ml-auto">{timeSheet.totalHours} hours</div>
        </div>
      }
      className="flex flex-col px-4 py-2 border-b last:border-b-0"
      onExpand={onExpand}
    >
      <div className="flex flex-auto my-4">
        {isLoading ? (
          <LoadingPlaceholder />
        ) : (
          <div className="flex flex-auto">
            <Calendar
              className="mx-auto"
              year={year}
              month={month}
              getHoliday={(day) => findHoliday(data.holidays, day)}
              renderDay={renderDay}
            />
            <div className="flex flex-col w-2/12 mt-16 px-8 py-2">
              {onLock && !timeSheet.locked && (
                <Button
                  className="inline-flex items-center justify-center mb-2"
                  onClick={() => {
                    onLock({ year, month })
                  }}
                >
                  <MdLock className="mr-2" /> Lock
                </Button>
              )}
              <Button
                onClick={onExport}
                className="inline-flex items-center justify-center"
              >
                <MdFileDownload className="mr-2" /> Export
              </Button>

              {!timeSheet.locked && onRemove && (
                <Button
                  kind="danger"
                  className="inline-flex items-center justify-center mt-2"
                  onClick={() => {
                    onRemove({ year, month })
                  }}
                >
                  <MdLock className="mr-2" /> Remove
                </Button>
              )}
            </div>
          </div>
        )}
      </div>
    </Expandable>
  )
}

const TimeSheetList = ({
  timeSheets,
  onRemove,
  onRequestData,
  getBasename,
  onLock,
}) => {
  if (timeSheets.length === 0) {
    return <EmptyPlaceholder />
  }

  return (
    <div className="flex flex-col">
      {timeSheets.map((timeSheet) => (
        <TimeSheetListItem
          key={[timeSheet.year, timeSheet.month]}
          timeSheet={timeSheet}
          getBasename={getBasename}
          onLock={onLock}
          onRemove={onRemove}
          onRequestData={onRequestData}
        />
      ))}
    </div>
  )
}

TimeSheetList.defaultProps = {
  timeSheets: [],
}

export default TimeSheetList
