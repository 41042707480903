import React from "react"
import classNames from "classnames"
import { observer } from "mobx-react"

import useHover from "../hooks/useHover"
import {
  dateAt,
  weekDays,
  weeksBetween,
  daysBetween,
  endOfISOWeek,
  endOfMonth,
  isSameMonth,
  isWeekend,
  startOfISOWeek,
  startOfMonth,
} from "../support/date"

import Popover from "./Popover"

const CalendarDay = ({ day, children, holiday, disabled }) => {
  const isWorkingDay = !isWeekend(day) && !holiday
  const ref = React.useRef()
  const isHovered = useHover(ref)
  return (
    <div
      ref={ref}
      className={classNames(
        "flex flex-col flex-1 py-4 px-6 table-cell border",
        {
          "opacity-25": disabled,
          "bg-gray-100": isWeekend(day),
          "bg-red-100": holiday,
        },
      )}
    >
      {holiday && isHovered && (
        <Popover referenceElement={ref} placement="top" className="p-2 text-xs">
          {holiday}
        </Popover>
      )}
      <div
        className={classNames("mr-2 text-lg", {
          "font-bold": !disabled,
          "text-red-500": !isWorkingDay,
        })}
      >
        {day.getDate()}
      </div>
      {!disabled && children}
    </div>
  )
}

const Calendar = ({ className, year, month, getHoliday, renderDay }) => {
  const date = dateAt(year, month)
  const weeks = weeksBetween(startOfMonth(date), endOfMonth(date))

  return (
    <div className={classNames(className, "table border-collapse")}>
      <div className="table-row">
        {weekDays().map((name) => (
          <div
            key={name}
            className="table-cell flex flex-1 flex-col p-6 text-gray-700"
          >
            {name}
          </div>
        ))}
      </div>

      {weeks.map((weekDay) => (
        <div
          key={weekDay.toISOString()}
          className="table-row"
          style={{ fontVariantNumeric: "tabular-nums" }}
        >
          {daysBetween(startOfISOWeek(weekDay), endOfISOWeek(weekDay)).map(
            (day) => (
              <CalendarDay
                key={day.toISOString()}
                day={day}
                holiday={getHoliday(day)}
                disabled={!isSameMonth(date, day)}
              >
                {renderDay(day)}
              </CalendarDay>
            ),
          )}
        </div>
      ))}
    </div>
  )
}

Calendar.defaultProps = {
  getHoliday: () => {},
  renderDay: () => {},
}

export default observer(Calendar)
