import React, { useRef } from "react"
import classNames from "classnames"
import PropTypes from "prop-types"
import { animated, useSpring } from "react-spring"
import ReactDOM from "react-dom"

import usePopper from "../hooks/usePopper"

const Popover = ({
  children,
  referenceElement,
  className,
  placement,
  modifiers,
  style,
  onDismiss,
  ...props
}) => {
  const popoverRef = useRef()
  const arrowRef = useRef()

  const spring = useSpring({
    from: {
      opacity: 0,
      transform: "translateY(5%)",
    },
    to: {
      opacity: 1,
      transform: "translateY(0%)",
    },
  })

  const {
    style: popperStyle,
    placement: popperPlacement,
    arrowStyle,
    scheduleUpdate,
  } = usePopper({
    referenceElement,
    popperNode: popoverRef,
    arrowNode: arrowRef,
    placement,
    modifiers,
  })

  scheduleUpdate()

  return ReactDOM.createPortal(
    <animated.div
      {...props}
      ref={popoverRef}
      style={{
        ...style,
        ...popperStyle,
        ...spring,
        transform: spring.transform.interpolate(
          (x) => `${popperStyle.transform} ${x}`,
        ),
      }}
      className={classNames("popover", popperPlacement, className)}
    >
      {children}
      <div className="arrow" ref={arrowRef} style={arrowStyle} />
    </animated.div>,
    document.querySelector("#root"),
  )
}

Popover.defaultProps = {
  onDismiss: () => {},
  placement: "auto",
}

Popover.propTypes = {
  children: PropTypes.node,
  className: PropTypes.any,
  style: PropTypes.object,
  onDismiss: PropTypes.func,
  placement: PropTypes.string,
}

export default Popover
