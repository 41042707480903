import "./global.css"
import React from "react"
import ReactDOM from "react-dom"
import { onSnapshot } from "mobx-state-tree"
import { Provider } from "mobx-react"
import { BrowserRouter } from "react-router-dom"
import { Draqula, DraqulaProvider } from "draqula"

import App from "./containers/App"
import Store from "./store"

const store = Store.create(
  localStorage.store ? JSON.parse(localStorage.store) : {},
)

onSnapshot(store, (snapshot) => {
  localStorage.store = JSON.stringify(snapshot)
})

const client = new Draqula("/graphql", {
  hooks: {
    beforeRequest: [
      (request) => {
        request.headers.set("Authorization", `Bearer ${store.token}`)
      },
    ],
    afterResponse: [
      (response) => {
        if (response.status === 401) {
          store.setToken(null)
        }
      },
    ],
  },
})

ReactDOM.render(
  <DraqulaProvider client={client}>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </DraqulaProvider>,
  document.getElementById("root"),
)
