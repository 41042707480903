import React from "react"
import classNames from "classnames"

export default ({ className, ...props }) => (
  <input
    {...props}
    className={classNames(
      className,
      "appearance-none bg-white border rounded px-2 py-1",
    )}
  />
)
