import React from "react"
import { observer, useLocalStore } from "mobx-react"
import gql from "graphql-tag"
import { useMutation } from "draqula"

import useCurrentUser from "../../hooks/useCurrentUser"
import Input from "../../components/Input"
import Button from "../../components/Button"
import Card from "../../components/Card"

const PROFILE_MUTATION = gql`
  mutation Mutation(
    $id: String!
    $workingHours: Int!
    $holidaysCalendarUrl: String
  ) {
    updateUser(
      input: {
        id: $id
        patch: {
          holidaysCalendarUrl: $holidaysCalendarUrl
          workingHours: $workingHours
        }
      }
    ) {
      clientMutationId
    }
  }
`

const ProfileRoute = ({ match, history, ...props }) => {
  const currentUser = useCurrentUser()

  const store = useLocalStore(() => ({
    ...currentUser,
    setWorkingHours(e) {
      store[e.target.name] = Number(e.target.value)
    },
    setHolidaysCalendarUrl(e) {
      store[e.target.name] = e.target.value
    },
  }))

  const { mutate } = useMutation(PROFILE_MUTATION)

  const handleSubmit = async (e) => {
    e.preventDefault()
    const { id, workingHours, holidaysCalendarUrl } = store
    await mutate({ id, workingHours, holidaysCalendarUrl })
    history.replace("/")
  }

  return (
    <>
      <h1 className="mb-8 text-2xl">{currentUser.name}</h1>
      <Card className="p-8">
        <form onSubmit={handleSubmit}>
          <label className="flex flex-col mb-4">
            <div className="mb-2 font-medium">Working Hours</div>
            <Input
              type="number"
              name="workingHours"
              value={store.workingHours}
              onChange={store.setWorkingHours}
            />
          </label>
          <label className="flex flex-col mb-4">
            <div className="mb-2 font-medium">Holidays Calendar URL</div>
            <Input
              name="holidaysCalendarUrl"
              value={store.holidaysCalendarUrl}
              onChange={store.setHolidaysCalendarUrl}
            />
            <div className="mt-1 text-gray-500 text-xs">
              Example:
              https://ajuntament.barcelona.cat/calendarifestius/ca/files/calendarifestius_ca.ics
            </div>
          </label>
          <Button>Save</Button>
        </form>
      </Card>
    </>
  )
}

export default observer(ProfileRoute)
