import React from "react"
import { observer } from "mobx-react"
import gql from "graphql-tag"
import { useQuery, useMutation, DraqulaContext } from "draqula"

import { startOfMonth, endOfMonth } from "../../support/date"
import Card from "../../components/Card"
import LoadingPlaceholder from "../../components/LoadingPlaceholder"
import TimeSheetList from "../../components/TimeSheetList"
import Button from "../../components/Button"

const USER_QUERY = gql`
  query UseTimeSheetsQuery($id: String!) {
    user(id: $id) {
      id
      name
      picture
      email
      holidaysCalendarUrl
      timeSheets {
        nodes {
          year
          month
          userId
          locked
          totalHours
        }
      }
    }
  }
`

const USER_TIME_ENTRIES = gql`
  query q(
    $userId: String!
    $start: Date!
    $end: Date!
    $holidaysCalendarUrl: String
  ) {
    ics(url: $holidaysCalendarUrl) {
      date
      summary
      uid
    }

    timeEntries(
      filter: {
        userId: { equalTo: $userId }
        date: { greaterThanOrEqualTo: $start, lessThanOrEqualTo: $end }
      }
    ) {
      nodes {
        date
        hours
      }
    }
  }
`

const LOCK_USER_TIME_ENTRIES = gql`
  mutation LockUserTimeEntries($userId: String!, $year: Int!, $month: Int!) {
    lockTimeSheet(input: { userId: $userId, year: $year, month: $month }) {
      clientMutationId
    }
  }
`

const TimeSheetsRoute = ({ match, ...props }) => {
  const { client } = React.useContext(DraqulaContext)
  const { isLoading, data, refetch } = useQuery(USER_QUERY, {
    id: match.params.id,
  })

  const { mutate } = useMutation(LOCK_USER_TIME_ENTRIES)

  const onLock = async ({ year, month }) => {
    if (confirm("Are you sure?")) {
      await mutate({ userId: data.user.id, year, month })
      refetch()
    }
  }

  if (isLoading) {
    return <LoadingPlaceholder />
  }

  return (
    <>
      <div className="flex mb-8">
        <h1 className="text-2xl">{data.user.name}</h1>
      </div>
      <Card>
        <TimeSheetList
          timeSheets={data.user.timeSheets.nodes}
          getBasename={(year, month) => [year, month, data.user.name].join("-")}
          onRequestData={async (date) => {
            const { ics, timeEntries } = await client.query(
              USER_TIME_ENTRIES,
              {
                start: startOfMonth(date),
                end: endOfMonth(date),
                userId: data.user.id,
                holidaysCalendarUrl: data.user.holidaysCalendarUrl,
              },
              {},
            )

            return {
              holidays: ics,
              timeEntries: timeEntries.nodes,
            }
          }}
          onLock={onLock}
        />
      </Card>
    </>
  )
}

export default observer(TimeSheetsRoute)
