import React from "react"
import { observer, useLocalStore } from "mobx-react"
import { range } from "lodash-es"

import { getYear, getMonth, monthName, dateAt } from "../../support/date"
import Button from "../../components/Button"
import Select from "../../components/Select"

const Picker = (props) => {
  const today = new Date()
  const year = getYear(today)
  const local = useLocalStore(() => ({
    month: getMonth(today),
    setMonth(month) {
      local.month = month
    },
  }))

  return (
    <div {...props}>
      <Select
        className="mr-2"
        value={local.month}
        onChange={(e) => {
          local.setMonth(e.target.value)
        }}
      >
        {range(12, 0).map((month) => (
          <option key={month} value={month}>
            {monthName(dateAt(year, month))}
          </option>
        ))}
      </Select>

      <Button asLink to={`/time-entry/${year}/${local.month}`}>
        New
      </Button>
    </div>
  )
}

export default observer(Picker)
