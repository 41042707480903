import React from "react"
import gql from "graphql-tag"
import { useQuery, useMutation, DraqulaContext } from "draqula"

import Card from "../../components/Card"
import LoadingPlaceholder from "../../components/LoadingPlaceholder"
import TimeSheetList from "../../components/TimeSheetList"
import { startOfMonth, endOfMonth } from "../../support/date"
import useCurrentUser from "../../hooks/useCurrentUser"

import Picker from "./Picker"

const CURRENT_USER_TIMESHEETS = gql`
  query CurrentUserTimesheetsQuery {
    currentUser {
      timeSheets {
        nodes {
          year
          month
          userId
          totalHours
          locked
        }
      }
    }
  }
`

const USER_TIME_ENTRIES = gql`
  query q($start: Date!, $end: Date!, $holidaysCalendarUrl: String) {
    ics(url: $holidaysCalendarUrl) {
      date
      summary
      uid
    }

    currentUser {
      timeEntries(
        filter: {
          date: { greaterThanOrEqualTo: $start, lessThanOrEqualTo: $end }
        }
      ) {
        nodes {
          date
          hours
          locked
        }
      }
    }
  }
`

const DELETE_TIME_SHEETS = gql`
  mutation q($input: DeleteTimeSheetsInput!) {
    deleteTimeSheets(input: $input) {
      clientMutationId
    }
  }
`

const TimeSheetsRoute = () => {
  const user = useCurrentUser()
  const { client } = React.useContext(DraqulaContext)

  const { isLoading, data, refetch } = useQuery(
    CURRENT_USER_TIMESHEETS,
    {},
    { cache: false },
  )

  const { mutate } = useMutation(DELETE_TIME_SHEETS)

  const onRemove = async ({ year, month }) => {
    if (
      confirm(`Do you really want to remove all entries from ${year}/${month}?`)
    ) {
      await mutate({
        input: { userId: user.id, year, month },
      })
      refetch()
    }
  }

  if (isLoading) {
    return <LoadingPlaceholder />
  }

  return (
    <>
      <div className="flex mb-8">
        <h1 className="text-2xl">Time Sheets</h1>
        <Picker className="ml-auto" />
      </div>
      <Card>
        <TimeSheetList
          timeSheets={data.currentUser.timeSheets.nodes}
          onRemove={onRemove}
          getBasename={(year, month) => [year, month, user.name].join("-")}
          onRequestData={async (date) => {
            const { ics, currentUser } = await client.query(
              USER_TIME_ENTRIES,
              {
                start: startOfMonth(date),
                end: endOfMonth(date),
                holidaysCalendarUrl: user.holidaysCalendarUrl,
              },
              {},
            )

            return {
              holidays: ics,
              timeEntries: currentUser.timeEntries.nodes,
            }
          }}
        />
      </Card>
    </>
  )
}

export default TimeSheetsRoute
