import React from "react"

const Spinner = ({ size, style, className, ...props }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 100 100"
    style={{ ...style, verticalAlign: "middle" }}
    {...props}
  >
    <g transform="translate(10 10)" className={className}>
      <circle
        fill="none"
        stroke="currentColor"
        strokeDasharray={`${(2 * Math.PI * 40) / 4} ${2 * Math.PI * 40 * 3}`}
        strokeWidth="20"
        cx="40"
        cy="40"
        r="40"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          from="0 40 40"
          to="360 40 40"
          dur="0.9s"
          repeatCount="indefinite"
        />
      </circle>
      <circle
        fill="none"
        opacity="0.3"
        stroke="currentColor"
        strokeWidth="20"
        cx="40"
        cy="40"
        r="40"
      />
    </g>
  </svg>
)

Spinner.defaultProps = {
  size: 50,
  className: "text-gray-500",
}

export default Spinner
