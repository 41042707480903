import React from "react"
import classNames from "classnames"

export default ({ user, className, ...props }) => (
  <div
    className={classNames(className, "flex items-center p-2 py-3")}
    {...props}
  >
    <img className="h-8 w-8 mr-3 rounded-full" src={user.picture} />
    <div className="flex flex-col">
      <div>{user.name}</div>
      <div className="text-gray-500 text-xs">{user.email}</div>
    </div>
  </div>
)
