import React, { useRef, useState, useEffect } from "react"
import PropTypes from "prop-types"
import { MdArrowDropDown } from "react-icons/md"
import { animated, useSpring } from "react-spring"

import useScrollHeight from "../hooks/useScrollHeight"

const Expandable = ({
  title,
  children,
  onExpand,
  onCollapse,
  className,
  ...props
}) => {
  const ref = useRef()
  const [isAnimating, setAnimating] = useState(false)
  const [isExpanded, setExpanded] = useState(false)
  const scrollHeight = useScrollHeight(ref, [children])

  useEffect(() => {
    setAnimating(true)
  }, [children])

  const arrowSpring = useSpring({
    transform: `rotate(${isExpanded ? 180 : 0}deg)`,
  })

  const spring = useSpring({
    onRest: () => {
      setAnimating(false)
    },
    to: {
      opacity: isExpanded ? 1 : 0,
      height: isExpanded ? scrollHeight : 0,
    },
  })

  const toggle = () => {
    setAnimating(true)
    if (isExpanded) {
      onCollapse()
    } else {
      onExpand()
    }
    setExpanded(!isExpanded)
  }

  const AnimatedIcon = animated(MdArrowDropDown)

  return (
    <div {...props} className={className}>
      <div
        className={["cursor-pointer flex flex-auto items-center"]}
        onClick={toggle}
      >
        <div className="flex-auto">{title}</div>
        <div className="self-start ml-8 text-grey whitespace-no-wrap">
          <AnimatedIcon className="inline" size={18} style={arrowSpring} />
        </div>
      </div>

      <animated.div
        ref={ref}
        style={{
          opacity: spring.opacity,
          ...(isAnimating && { height: spring.height, overflow: "hidden" }),
        }}
      >
        {(isAnimating || isExpanded) && children}
      </animated.div>
    </div>
  )
}

Expandable.defaultProps = {
  onExpand: () => {},
  onCollapse: () => {},
}

Expandable.propTypes = {
  onExpand: PropTypes.func,
  onCollapse: PropTypes.func,
}

export default Expandable
